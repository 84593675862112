@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700&display=swap');

@mixin dynamic-size($based, $scale, $css: width) {
	#{$css}:calc($based + $scale * (100vw - 10px)/600);
}

html{
    font-size:18px;
}

body {
    // background:#eaa1a4 !important;
    --bs-body-color: #4d4d4d;
}

.app-content {
    margin: auto;
    max-width: 600px;
    background: #eaa1a4;
    font-family: "Noto Sans TC", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
    display:flex;
    flex-direction:column;
    min-height:100vh;
    position:relative;
}

.body-wrap {
    flex-grow:1;
    color: #ffffff;
    position:relative;
    z-index:1;
    @include dynamic-size(16px, 10, 'padding');
    &.introduction {
        @include dynamic-size(36px, 10, 'padding');
        position:relative;
        height:100%;
        .title{
            @include dynamic-size(10px, 10, 'font-size');
            font-weight: 500;
            margin-bottom: 1rem;
        }
        .bg{
            position:absolute;
            left:0;
            bottom:0;
            width:100%;
            height:100%;
            pointer-events:none;
            img{
                width:100%;
                height:100%;
                object-fit:cover;
            }
        }
        .sis-slogan{
            position:absolute;
            left: 0;
            top: 33%;
            width: 51%;
            z-index: 10;
        }
        .btn-container{
            position:absolute;
            left:0;
            top:68%;
            width:100%;
            margin:0;
        }
    }

    &.questioning{
        .btn-container{
            
        }
    }

    header{
        position:relative;
        margin:0;
        min-height:42px;
        .sis-logo{
            position:absolute;
            left:50%;
            top:0;
            transform:translateX(-50%);
            width:108px;
        }
        .back-btn{
            background:none;
            border:none;
            margin:0;
            padding:0;
            color:#fff;
            font-size:26px;
            line-height:1;
        }
        .current-q{
            font-size:15px;
            font-weight:500;
            font-weight:normal;
        }
    }

    .progress-bar{
        background:#ffffff;
        border-radius:5px;
        height:6px;
        position:relative;
        margin:25px 0 0;
        .bar{
            background:#d62548;
            height:100%;
            width:0;
            border-radius:5px;
            transition:width .4s ease-in-out;
        }
    }

    .q-area{
        margin:25px 0;
        .q-title{
            font-size:1.3rem;
            line-height:1.4;
            font-weight:500;
            margin-bottom:1rem;
        }
        .options{
            margin-top:30px;
            .option{
                margin:10px 0 0;
                &:first-child{
                    margin-top:0;
                }
                .btn{
                    background:#ffffff;
                    padding:1.2em;
                    width:100%;
                    text-align:left;
                    line-height:1.2;
                    border:2px solid #ffffff;
                    transition:all .2s;
                }
                .btn-check:checked+.btn{
                    background:#4bab64;
                    color:#fff;
                }
            }
        }
    }

    .result-area{
        margin:30px 0 0;
        font-size:.9rem;
        font-weight:500;
        .sis-label{
            display:inline-block;
            background:#d62548;
            color:#fff;
            padding:0.3em 1em;
            font-weight:500;
            border-radius:2rem;
        }
        .result-title{
            font-size:inherit;
        }
        .result-text{
            margin-top:.2rem;
            font-size:3.3rem;
            font-weight:500;
            line-height:1;
        }
        .result-row{
            margin:10px 0 0;
            .row{
                --bs-gutter-x: 10%;
                >*{
                    position:relative;
                    &:first-child{
                        &:before{
                            content:none;
                        }
                    }
                    &:before{
                        content:"";
                        position:absolute;
                        left:-7%;
                        top:50%;
                        transform:translateY(-50%);
                        width:1px;
                        height:60px;
                        background:rgba(#e9a0a3, .5);
                    }
                }
            }
        }
        .star{
            display:inline-block;
            width:30px;
            height:30px;
            line-height:30px;
            margin-right:5px;
            vertical-align:top;
        }
        .card{
            margin:10px 0 0;
        }
    }

    ol.custom-ol{
        counter-reset: custom-counter;
        list-style-type: none;
        padding-left: 0;
        li{
            position:relative;
            padding:10px 0 0 28px;
            margin:10px 0 0;
            border-top:1px solid rgba(#000, .1);
            &:first-child{
                border-top:none;
                margin-top:0;
                padding-top:0;
                &:before{
                    top:0;
                }
            }
            &:before {
                content: counter(custom-counter) ". ";
                counter-increment: custom-counter;
                position:absolute;
                left:0;
                top:10px;
            }
        }
    }

    .cash-rewards{
        margin:30px 0 0;
        letter-spacing:.15em;
        .reward-title{
            font-size:1.88rem;
            letter-spacing:.15em;
        }
        img{
            max-width:100%;
        }
    }

    .brand{
        letter-spacing:.05em;
        .brand-title{
            font-size:1rem;
            color:#000;
        }
        .brand-caption{
            font-size:.7rem;
            margin:0;
        }
        .brand-address{
            font-size:.7rem;
            margin:.3em 0 0;
        }

        .reward-name{
            font-size:1rem;
            margin:0;
            color:#000000;
        }

        .reward-desc{
            font-size:.9rem;
            margin:0;
        }

        .whatsapp-contact{
            display:flex;
            align-items:center;
            font-size:1.3rem;
            color:#000;
            margin:1em 0 0;
            img{
                width:30px;
                margin-right:5px;
            }
            a{
                text-decoration: none;
                color:#4d4d4d;
            }
        }

        .website-contact{
            margin:1em 0 0;
        }

        .price{
            position:absolute;
            right:16px;
            bottom:16px;
            text-align:right;
            font-size:0;
            .sis{
                padding-left:45px;
                color:#d62548;
                font-size:2.3rem;
                line-height:1;
                background-repeat:no-repeat;
                background-size:36px auto;
                background-position:left center;
                display:inline-block;
            }
            .original{
                position:relative;
                margin:5px 10px 0 0;
                padding-left:32px;
                color:#717071;
                font-size:1.1rem;
                line-height:1;
                background-repeat:no-repeat;
                background-size:30px auto;
                background-position:left center;
                display:inline-block;
                &::after{
                    content: "";
                    position:absolute;
                    width:100%;
                    height:1px;
                    background:#717071;
                    left:0;
                    top:50%;
                    transform:rotate(-12deg);
                }
            }
        }
    }

    .play-again-button{
        text-align:center;
        margin:10px 0 0;
        a{
            color:#ffffff;
            text-decoration:none;
            letter-spacing:.1em;
        }
    }
}

.q-bg{
    position:absolute;
    left:0;
    bottom:10%;
    width:100%;
    height:72%;
    pointer-events:none;
    z-index:0;
    &.hidden{
        opacity:0;
    }
    img{
        width:100%;
        height:100%;
        object-fit:cover;
    }
}

@keyframes shadow-anim {
    0% {
        width: 100%;
        height: 100%;
        opacity: 1;
    }
    40%, 100% {
        width: calc(100% + 26px);
        height: calc(100% + 26px);
        opacity:.0;
    }
}

.btn-container{
    margin-top:3rem;
    @include dynamic-size(-16px, -10, 'margin-left');
    @include dynamic-size(-16px, -10, 'margin-right');
    .wrap{
        position: relative;
        @include dynamic-size(16px, 10, 'margin-left');
        @include dynamic-size(16px, 10, 'margin-right');
        &:after{
            content: "";
            display: block;
            position:absolute;
            left:50%;
            top:50%;
            border-radius:0.375rem;
            transform:translate(-50%,-50%);
            width:100%;
            height:100%;
            background:#ffffff;
            animation: shadow-anim 3s infinite cubic-bezier(0.04, 0.24, 0.58, 1);
            animation-fill-mode: forwards;
        }
    }
    &.disabled{
        .wrap{
            &:after{
                display:none;
            }
        }
    }
    .btn{
        position:relative;
        z-index:1;
        display:flex;
        justify-content: center;
        align-items:center;
        background:#4bab64;
        color:#fff;
        text-align:center;
        padding:.4em 0 .5em;
        width:100%;
        font-size:28px;
        letter-spacing:.15em;
        line-height:1.2;
        border:2px solid #ffffff;
        .arrow-start{
            width:28px;
            margin-left:10px;
        }
    }
}

.form-control, .input-group-text{
    padding:.8rem 1rem;
    border:2px solid #ffffff;
    background-color:#fff;
    box-shadow: none !important;
}

.form-control{
    transition: none !important;
    &:focus {
        border-color:#ffffff;
    }

    &[invalid] {
        border: 2px solid red;
    }
}

.input-group-prepend .input-group-text{
    border-top-right-radius:0;
    border-bottom-right-radius:0;
}

.form-group{
    margin:10px 0 0;
}

.input-group.phone-group{
    &.invalid{
        .input-group-text{
            border-color:red;
        }
    }
    .input-group-text{
        border-right:none;
    }
    .form-control{
        border-left:none;
    }
    .icon-flag{
        position:absolute;
        right:20px;
        top:50%;
        z-index:10;
        transform: translateY(-50%);
        width:30px;
        img{
            display:block;
        }
    }
}

.phone-prefix{
    position:relative;
    &:after{
        content: "";
        display: block;
        position:absolute;
        right:1px;
        top:33%;
        bottom:33%;
        width:1px;
        background:#000;
    }
}

.loading-spinner{
    --bs-spinner-width:1.85rem;
    --bs-spinner-height:1.85rem;
}

.card-body{
    padding:20px;
}

footer{
    background:#d62548;
    color:#ffffff;
    padding:30px;
    label{
        display:block;
        font-weight:500;
        margin-bottom:1rem;
    }
    a{
        color:inherit;
        font-size:1.25rem;
        display:inline-block;
        padding:0 0 0 45px;
        text-decoration:none;
        background-size:34px auto;
        background-repeat:no-repeat;
        background-position:left center;
        margin:10px 0 0;
    }
    .copyright{
        font-size:.7rem;
        margin:40px 0 0;
    }
}